<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Einbruchschutz / Heroal - RC 2 <br />
    </h2>
    <div class="container pl-2 mx-auto pt-2 lg:p-32">
      <h2
        class="text-4xl md:text-6xl py-4 lg:text-6xl pb-10"
        style="color: #f27405"
      >
        Heroal Sicherheitsrolladen RC 2
      </h2>
      <article class="grid grid-cols-2">
        <figure class="row-span-4 col-span-full lg:col-span-1">
          <img
            src="../assets/img/heroal/rc2/rc2.webp"
            class="w-full"
            alt="heraol rc2 sicherheitsrollade"
          />
        </figure>

        <div class="col-span-full lg:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Schutz und Wohnkomfort mit heroal Aluminium Rollläden von
            <span class="font-semibold">BE-RO-MA</span>
          </h3>
          <p class="px-2 w-full md:pl-10 md:pb-3">
            Rollladensysteme von heroal schützen Ihre Privatsphäre, sorgen für
            ein angenehmes Raumklima und eine verbesserte Energieeffizienz Ihres
            Hauses. Dank des Werkstoffs Aluminium sind sie besonders
            witterungsbeständig, wartungsarm und langlebig. <br /><br />
            <span class="font-semibold">BE-RO-MA</span> ist als heroal-Partner
            der richtige Ansprechpartner für Sie in Berlin-Brandenburg wenn es
            um den Einbau oder Austausch Ihrer Rollläden geht. Mit heroal
            Rollladensystemen haben Sie größtmöglichen Gestaltungsspielraum.
            Wählen Sie Ihre Rollläden individuell in unterschiedlichen Größen
            mit verschiedenen Rollladenkästen und der breiten Palette der
            RAL-Farben. Dank unterschiedlicher Kastensysteme lassen sich
            klassische oder moderne Lösungen realisieren. Sie haben zudem die
            Möglichkeit Ihre Rollläden als Vorbaurollladen direkt vor das
            Fenster zu setzen, oder sie mit Unterputz-Blendsystemen oder
            Aufsatzkasten unsichtbar in die Fassade zu integrieren. So passt
            sich der Rollladen perfekt an die Optik Ihres Hauses an.<br /><br />
          </p>
        </div>
        <div class="col-span-full">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Einbruchschutz auf Knopfdruck
          </h3>
          <p class="px-2 w-full md:pl-10 md:pb-3">
            Nicht nur aufgrund des materiellen Verlustes sondern insbesondere
            auch aufgrund der psychischen Folgen durch das Eindringen in die
            Privatsphäre ist ein Einbruch oftmals mit nachhaltigen Problemen
            verbunden. Auch in Berlin-Brandenbrug wird immer wieder von
            Einbrüchen berichtet. Investitionen in einbruchhemmende Maßnahmen
            zahlen sich demnach in zweierlei Hinsicht aus.
            <span class="font-semibold">BE-RO-MA</span> hilft Ihnen dabei, denn
            mit dem Einbau hochwertiger heroal Rollladensysteme können Sie den
            Einbruchschutz Ihres Hauses deutlich verbessern. Dabei können sie
            einen Einbruchschutz bis zur Widerstandsklasse RC 4 erreichen, das
            bedeutet, dass heroal Rollläden erfahren Tätern mit Werkzeugen wie
            Stemmeisen und Akku-Bohrmaschine bis zu 10 Minuten standhalten.
            Einen Zeit, in der viele Täter das Weite suchen. Für den
            Einbruchschutz sorgen beispielsweise die Hochschiebesicherung und
            eine besonders stabile Führungsschiene, welche das Aushebeln und
            Hochschieben des Rollladens erschweren. Statten Sie Ihre Rollladen
            mit einer automatischen Steuerung aus, so können Sie auch von
            Unterwegs Ihre Anwesenheit simulieren und schrecken Einbrecher ab.
            <br /><br />
          </p>
        </div>
        <div class="col-span-full">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Verbessern Sie die Energieeffizienz Ihres Hauses mit HEROAL
            Rollläden
          </h3>
          <p class="px-2 w-full md:pl-10 md:pb-3">
            Energieeffizienz fängt vor Ihrem Fenster an.
            <span class="font-semibold">BE-RO-MA</span> bietet Ihnen mit den
            energieeffizienten Rollläden von heroal im Sommer einen effektiven
            Hitzeschutz und einen geringeren nächtlichen Wärmeverlust im Winter.
            Bei geschlossenem Rollladen kann dieser Wärmeverlust um bis zu 56 %
            reduziert werden. So sorgen unsere Rollläden ganzjährig für ein
            angenehmes Wohnklima in Ihrem Zuahsue. Im Falle einer Sanierung
            können Sie für den Einbau energiesparender Rollläden zudem
            Fördermittel aus unterschiedlichen Programmen wir beispielsweise der
            KfW erhalten.
          </p>
        </div>
      </article>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Allgemein">
            <p class="text-lg">
              <span class="font-bold">HEROAL RS 53 RC2</span> <br />
              Die extrem biegfesten Standard Safe-Stäbe mit einer Nenndicke von
              14 mm und einem hochdichzen Schaum sind für den von
              Sicherheitsrollladen gemäß DIN 1627 bis zu der Widerstandsklasse
              RC2 ausgelegt. <br />
              Der HEROAL RS 53 RC2 erreicht ausgezeichnete Windlastwerte 6 bei
              Elemtbreiten bis 3.200 mm. <br />
              Zusätzlich wurde der HEROAL Rs 53 RC2 mit Hagelschlagklasse 7
              ausgezeichnet.
            </p>
          </tab>
          <tab title="Maßen">
            <div class="text-lg">
              <div class="flex flex-col lg:flex-row">
                <p>
                  <span class="font-bold">Systemmaße und -eigenschaften</span>
                  <br />
                  Deckbreite: 51 mm <br />
                  max. Breite / Höhe: 5.500 mm/ 4.000 mm <br />
                  max. Fläche: 16,5 m² <br />
                  Fertigpanzergewicht: 10,5 kg / m² <br />
                  Windlastwiderstandsklasse 6 bei Elementbreiten bis 3.200 mm
                  <br />
                  erhöhte Einbruchhemmung: RC 2 <br />
                  optimierte Schalldämmung: +10 dB <br />
                  extrem stabil durch außergewöhnliche Wandtstärke und
                  hochdichte Ausschäumung <br />
                  Hagelschlagklasse 7 <br />
                  mögliche U-Gesamtwertbesserung von bis zu 42 Prozent
                  <br /><br />
                  <span class="font-bold">Gestaltungsvarianten und Design</span>
                  <br />
                  hochwittertungsbeständige OBerflächenbeschichtung mit
                  2-Schicht-Dicklack
                </p>
                <div class="ml-4">
                  <img
                    src="../assets/img/heroal/rc2/rc2-masse.webp"
                    alt="rc2 messung"
                  />
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </div>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },
};
</script>
